import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  addressFormat,
  checkWalletAllTransactionsForAddress,
} from "../services/Helper";
import { useSelector } from "react-redux";

const TransactionHistory = () => {
  const selectedNetwork = useSelector((state) => state.network);
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/transactions/get`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: {
              chainId: selectedNetwork?.chainId,
            },
          }
        );

        let transaction = response?.data?.transaction;

        console.log("selected network tx : ", transaction);
        setTransactions(transaction);
      } catch (error) {
        console.error("Error fetching adminn details:", error);
      }
    }

    getData();
  }, [selectedNetwork]);
  return (
    <div>
      <div className="transactions-list">
        <ul>
          <li
            onClick={async () => {
              const url = await checkWalletAllTransactionsForAddress();
              window.open(url, "_blank");
            }}
          >
            <div className="transactions-list-left">
              <figcaption>
                <h3>Wallet History</h3>
                {/* <p>1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa</p> */}
              </figcaption>
            </div>
            <div className="transactions-list-right">
              <h3>
                <figure>
                  <img src="images/receive-icon.png" alt="send" />
                  <span
                    style={{
                      marginLeft: "5px",
                    }}
                  ></span>
                  <img src="images/send-icon.png" alt="send" />
                </figure>
              </h3>
            </div>
          </li>

          {transactions && transactions.length > 0 ? (
            transactions.map((item, key) => (
              <li
                key={key}
                onClick={() => {
                  window.open(
                    `${selectedNetwork?.explorerURL}${item?.hash}`,
                    "_blank"
                  );
                }}
              >
                <div className="transactions-list-left">
                  <figure
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img src="images/send-icon.png" alt="send" />
                    <h3
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      Send
                    </h3>
                  </figure>
                  <figcaption>
                    <p>{addressFormat(item?.receiver, 7)}</p>
                  </figcaption>
                </div>
                <div className="transactions-list-right">
                  <h3>
                    {item?.amount} {item?.crypto}
                  </h3>
                  <p>{new Date(item?.createdAt).toLocaleDateString()}</p>
                </div>
              </li>
            ))
          ) : (
            <p>No transactions found.</p>
          )}

          {/* Received transactions */}
          {/* <li>
            <div className="transactions-list-left">
              <figure>
                <img src="images/receive-icon.png" alt="send" />
              </figure>
              <figcaption>
                <h3>Receive</h3>
                <p>1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa</p>
              </figcaption>
            </div>
            <div className="transactions-list-right">
              <h3>
                400 <i className="ti ti-currency-bitcoin" />
              </h3>
              <p>$400 USD </p>
            </div>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default TransactionHistory;
