import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Head from "../components/Head";
import {
  addressFormat,
  estimateGasFee,
  getWalletAddress,
  NATIVE,
} from "../services/Helper";
import WalletBalance from "../components/BalanceCard";
import axios from "axios";
import { Loader } from "../components/Loader/Loader";
import { useSelector } from "react-redux";

const ConfirmTxDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [gasFee, setGasFee] = useState(0);
  const [walletAddress, setWalletAddress] = useState(null);
  const livePrice = useSelector((state) => state.price);
  const selectedNetwork = useSelector((state) => state.network);

  const data = location.state;

  async function InitializeWalletAddress() {
    const wallet = await getWalletAddress(
      selectedNetwork?.blockchainType.toUpperCase()
    );
    setWalletAddress(wallet);
  }

  console.log("data : ", data);

  useEffect(() => {
    InitializeWalletAddress();

    const fetchBalance = async () => {
      try {
        const gas = await estimateGasFee(
          data?.receiverAddress,
          data?.inputAmount
        );
        setGasFee(gas);
      } catch (error) {
        console.error("Error fetching balance:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedNetwork?.blockchainType.toUpperCase() === "EVM") {
      fetchBalance();
    } else {
      setLoading(false);
    }
  }, [walletAddress]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/transactions/transfer-crypto`,
        {
          chainId: data?.chainId,
          tokenContractAddress: data?.contractAddress,
          receiver: data?.receiverAddress,
          amount: Number(data?.inputAmount),
          decimals: data?.decimals.toString(),
          symbol: data?.symbol,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log("response from tx : ", response);

      if (response?.status == 201) {
        alert("Transaction successful");
        navigate("/");
      } else {
        alert("Transaction failed. Please try again");
      }
    } catch (error) {
      console.log("Error:", error);
      alert(error.response?.data?.message || "Transaction failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/send-crypto">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <div className="send-crypto-des">
            <h2>Confirm Details</h2>

            <WalletBalance
              network={selectedNetwork}
              balance={data?.inputAmount}
              symbol={data?.symbol}
              livePrice={livePrice}
              isNative={data?.contractAddress === NATIVE}
            />

            <div className="total-usd-box">
              <ul>
                <li>
                  <h5>Transfering Fund</h5>
                </li>

                <li>
                  <h5>From</h5>
                  <p>
                    <span>To</span>
                  </p>
                </li>

                <li>
                  <h5>{addressFormat(walletAddress, 5)}</h5>
                  <p>
                    <span>{addressFormat(data?.receiverAddress, 5)}</span>
                  </p>
                </li>
              </ul>
            </div>

            <div className="total-usd-box">
              <ul>
                <li>
                  <h5>Transfer</h5>
                  <p>
                    <span>
                      {data?.inputAmount} {data?.symbol}
                    </span>
                  </p>
                </li>
                {selectedNetwork?.blockchainType.toUpperCase() === "EVM" && (
                  <>
                    <li>
                      <h5>Fees</h5>
                      <p>
                        <span>
                          {(Number(gasFee) / 10 ** 18).toFixed(6)}{" "}
                          {selectedNetwork?.symbol}
                        </span>
                      </p>
                    </li>
                    <li>
                      <h5>Total</h5>
                      <p>
                        <span>
                          {data?.inputAmount} {data?.symbol} +{" "}
                          {(Number(gasFee) / 10 ** 18).toFixed(6)}{" "}
                          {selectedNetwork?.symbol}
                        </span>
                      </p>
                    </li>
                  </>
                )}
              </ul>
            </div>
            <div className="form-submit-btn">
              <button className="btn" onClick={handleSubmit}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Password Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="send-crypto-des">
          <h2>Login Password</h2>
        </div>

        <form onSubmit={handlePasswordSubmit}>
          <div className="form-group">
            <div className="form-group-inner">
              <input
                type="password"
                value={password}
                className="form-control"
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={loading}
              />
            </div>
          </div>

          <div className="form-submit-btn">
            <button
              className="btn"
              // onClick={handleTransfer}
              disabled={loading}
              type="submit"
            >
              {loading ? "Processing..." : "Submit"}
            </button>
          </div>
        </form>
      </Modal> */}
    </>
  );
};

export default ConfirmTxDetails;
