import { createSlice } from "@reduxjs/toolkit";

// Fallback to empty object if no initial state is found in localStorage
const initialState =
  typeof window !== "undefined" && localStorage.getItem("livePrice")
    ? JSON.parse(localStorage.getItem("livePrice"))
    : {};

export const priceSlice = createSlice({
  name: "livePrice",
  initialState,
  reducers: {
    setPrice: (state, action) => {
      console.log("action.payload : ", action.payload);

      const updatedState = { ...state, ...action.payload };

      // Persisting updated state to localStorage
      if (typeof window !== "undefined") {
        localStorage.setItem("livePrice", JSON.stringify(action.payload));
      }
      return updatedState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPrice } = priceSlice.actions;

export default priceSlice.reducer;
