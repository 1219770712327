import React, { useState } from "react";
import { getContractData } from "../../services/Helper";
import axios from "axios";
import { Loader } from "../Loader/Loader"; // Assuming you have a Loader component
import AlertModel from "./AlertModel";

const ImportToken = ({ setReload, reload }) => {
  const [existAddressMsg, setExistAddressMsg] = useState("");
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [alertData, setAlertData] = useState(null); // State to handle the AlertModel visibility
  const selectedCrypto = JSON.parse(localStorage.getItem("selectedCrypto"));

  const chainId = selectedCrypto?.chainId;

  const [formData, setFormData] = useState({
    name: "",
    contractAddress: "",
    symbol: "",
    decimals: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};

    // EVM contractAddress validation
    if (!formData?.contractAddress) {
      newErrors.contractAddress = "Contract Address is required";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Start loading

    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      try {
        let info = null;
        if (selectedCrypto?.blockchainType.toUpperCase() === "EVM") {
          info = await getContractData(formData.contractAddress);
        } else if (selectedCrypto?.blockchainType.toUpperCase() === "TRON") {
          const url = `${process.env.REACT_APP_API_URL}/users/tron-token-details`;
          console.log("url : ", url);

          const response = await axios.post(
            url,
            {
              contractAddress: formData.contractAddress,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
          info = response?.data?.data;
          console.log("response info of getContractData : ", info);
        } else {
          setAlertData({
            status: false,
            title: "Invalid Inputs",
            subtitle: "Please select a valid blockchain.",
          });

          return;
        }

        if (info && info?.status === "success" && info?.info) {
          const updatedFormData = {
            contractAddress: formData?.contractAddress,
            name: info?.info?.name,
            symbol: info?.info?.symbol,
            decimals: info?.info?.decimal,
            chainId: chainId,
          };

          console.log(
            "-------------------> updatedFormData : ",
            updatedFormData,
            typeof chainId
          );

          try {
            await axios.post(
              `${process.env.REACT_APP_API_URL}/tokens/add`,
              updatedFormData,
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              }
            );

            // Call the callback function after token import is done

            setReload(!reload);

            setErrors({});
            setExistAddressMsg("");
            resetForm();
          } catch (error) {
            console.log("error in catch block", error);
            setExistAddressMsg("This address already exists");
          }
        } else {
          setErrors({ contractAddress: "Invalid contract address" });
          setIsValidAddress(false);
        }
      } catch (error) {
        console.log("Error fetching contract data", error);
        setErrors({ contractAddress: "Failed to fetch contract data" });
        setIsValidAddress(false);
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setErrors(formErrors);
      setIsValidAddress(false);
      setIsLoading(false); // Stop loading
    }
  };

  const handleBlur = async () => {
    setIsLoading(true); // Start loading

    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      try {
        let info = null;
        if (selectedCrypto?.blockchainType.toUpperCase() === "EVM") {
          info = await getContractData(formData.contractAddress);
        } else if (selectedCrypto?.blockchainType.toUpperCase() === "TRON") {
          const url = `${process.env.REACT_APP_API_URL}/users/tron-token-details`;
          console.log("url : ", url);

          const response = await axios.post(
            url,
            {
              contractAddress: formData.contractAddress,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
          info = response?.data?.data;
          console.log("response info of getContractData : ", info);
        }

        if (info && info?.status === "success" && info?.info) {
          // Update formData with the retrieved info
          setFormData({
            ...formData,
            name: info.info.name,
            symbol: info.info.symbol,
            decimals: info.info.decimal,
          });
          setIsValidAddress(true);
          setErrors({});
        } else {
          setErrors({ contractAddress: "Invalid contract address" });
          setIsValidAddress(false);
        }
      } catch (error) {
        console.log("Error fetching contract data", error);
        setErrors({ contractAddress: "Failed to fetch contract data" });
        setIsValidAddress(false);
      } finally {
        setIsLoading(false); // Stop loading
      }
    } else {
      setErrors(formErrors);
      setIsValidAddress(false);
      setIsLoading(false); // Stop loading
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      contractAddress: "",
      symbol: "",
      decimals: "",
      chainId: "",
    });
    setErrors({});
    setIsValidAddress(false);
    setExistAddressMsg("");
    setReload(!reload);
  };

  return (
    <>
      {isLoading ? <Loader /> : null}
      {alertData && (
        <AlertModel
          status={alertData.status}
          title={alertData.title}
          subtitle={alertData.subtitle}
          navigation={alertData.navigation}
        />
      )}
      <div
        className="modal fade import-token-bg"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={resetForm}
            />
            <div className="modal-body">
              <div className="import-token-des">
                <h2>Import Token</h2>
                <div className="tokens-transactions-box">
                  <div className="tab-content" id="pills-tabContent">
                    <form onSubmit={handleSubmit}>
                      <div
                        className="tab-pane fade show active"
                        id="pills-profile2"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab2"
                      >
                        <div className="form-group">
                          <label>Token contract address</label>
                          <div className="form-group-inner">
                            <div className="fild-icon">
                              <i className="ti ti-number-123" />
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter address"
                              name="contractAddress"
                              value={formData.contractAddress || ""}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.contractAddress && (
                              <span className="error">
                                {errors.contractAddress}
                              </span>
                            )}
                            <span className="error">{existAddressMsg}</span>
                          </div>
                        </div>
                        {isValidAddress && (
                          <>
                            <div className="form-group">
                              <label>Token Name</label>
                              <div className="form-group-inner">
                                <div className="fild-icon">
                                  <i className="ti ti-file-broken" />
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Name"
                                  name="name"
                                  value={formData.name || ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Token Symbol</label>
                              <div className="form-group-inner">
                                <div className="fild-icon">
                                  <i className="ti ti-file-broken" />
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Symbol"
                                  name="symbol"
                                  value={formData.symbol || ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Token Decimal</label>
                              <div className="form-group-inner">
                                <div className="fild-icon">
                                  <img src="images/decimal.png" alt="decimal" />
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={0}
                                  name="decimals"
                                  value={formData.decimals || ""} // Provide default value of 0 if formData.decimals is undefined
                                  disabled
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="form-submit-btn">
                          {isLoading ? (
                            <button className="btn" disabled>
                              Loading...
                            </button>
                          ) : (
                            <button className="btn">Import Token</button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportToken;
