import { configureStore } from "@reduxjs/toolkit";
import networkReducer from "./networkSlice";
import priceReducer from "./cryptoPriceSlice";

export default configureStore({
  reducer: {
    network: networkReducer,
    price: priceReducer, // Add price reducer
  },
});
