import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Head from "../../components/Head";
import { CopyToClipboard } from "react-copy-to-clipboard";

const SecureStorage = () => {
  const navigate = useNavigate();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [privateKey, setPrivateKey] = useState("");
  const [validated, setValidated] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState("");
  const [mnemonicPhrase, setMnemonicPhrase] = useState("");
  const [copied, setCopied] = useState(false);
  const [copiedWordIndex, setCopiedWordIndex] = useState(null);

  const [formData, setFormData] = useState({
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};

    if (!formData.password) {
      newErrors.password = "Password is required";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await axios
          .post(
            `${process.env.REACT_APP_API_URL}/users/check-password`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setErrors("");
            setWrongCredentials("");
            if (response?.data?.data?.evmWallet?.privateKey) {
              setPrivateKey(response?.data?.data?.evmWallet?.privateKey);
              setValidated(true);
              setMnemonicPhrase(
                response?.data?.data?.evmWallet?.mnemonic?.phrase
              );
            } else {
              setWrongCredentials("Password not matched");
            }
          })
          .catch((error) => {
            console.error("error", error);
            setErrors("");
            setWrongCredentials("Password not matched");
          });
      } catch (error) {
        console.log("catch error", error);
        setErrors({ password: "Invalid password" });
      }
    } else {
      setErrors(formErrors);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <a href="#" onClick={() => handleNavigate("/account")}>
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </a>
          </div>

          <div className="send-crypto-des">
            <h2>Mnemonic</h2>

            {!validated ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>Password</label>
                  <div className="form-group-inner password-fild-box">
                    <div className="fild-icon">
                      <i className="ti ti-lock" />
                    </div>
                    <input
                      style={
                        {
                          // marginRight: "80px",
                        }
                      }
                      type={passwordVisible ? "text" : "password"}
                      className="form-control"
                      placeholder="Enter Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <span className="error">{errors.password}</span>
                    )}
                    <span className="error">{wrongCredentials}</span>
                    <div
                      className="eye-view-btn"
                      onClick={togglePasswordVisibility}
                    >
                      <i
                        className={`ti ${
                          passwordVisible ? "ti-eye-off" : "ti-eye"
                        }`}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-submit-btn">
                  <button className="btn">Submit</button>
                </div>
              </form>
            ) : (
              <>
                <div className="secure-storage-box">
                  <figure>
                    <img src="images/key.png" alt="key" />
                  </figure>
                  <figcaption>
                    <h3>Private Key</h3>
                    <p>{privateKey}</p>
                    <div className="copy-storage">
                      <CopyToClipboard
                        text={privateKey}
                        onCopy={() => setCopied(true)}
                      >
                        <button>
                          <i
                            className={`ti ${
                              copied ? "ti-checkbox" : "ti-copy"
                            }`}
                          />
                          <span>{copied ? "Copied!" : "Copy"}</span>
                        </button>
                      </CopyToClipboard>
                    </div>
                  </figcaption>
                </div>

                <div className="seed-phrases-box">
                  <div className="seed-phrases-head">
                    <h3>Seed Phrases</h3>
                  </div>
                  <div className="row">
                    {mnemonicPhrase.split(" ").map((word, index) => (
                      <div className="col-md-6" key={index}>
                        <div className="form-group">
                          <div
                            className="form-group-inner"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div className="fild-icon">{index + 1}</div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder={`Enter Word ${index + 1}`}
                              value={word}
                              diabled
                            />
                            <div
                              style={{
                                position: "relative",
                                marginLeft: "-100px",
                                paddingRight: "-100px",
                                fontSize: "12px",
                              }}
                            >
                              <CopyToClipboard
                                text={word}
                                onCopy={() => setCopiedWordIndex(index)}
                              >
                                <div
                                  style={{
                                    // position: "absolute",
                                    fontSize: "28px",
                                    color:
                                      copiedWordIndex === index
                                        ? "#d8a80a"
                                        : "#ccc",
                                  }}
                                >
                                  <i
                                    className={`ti ${
                                      copiedWordIndex === index
                                        ? "ti-checkbox"
                                        : "ti-copy"
                                    }`}
                                  />
                                </div>
                              </CopyToClipboard>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecureStorage;
