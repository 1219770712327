// import React, { useEffect, useState, useCallback } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import Head from "../components/Head";
// import ImportToken from "../components/Models/ImportToken.js";
// import WalletBalance from "../components/BalanceCard.js";
// import { Loader } from "../components/Loader/Loader";
// import axios from "axios";
// import {
//   getNativeTokenBalance,
//   getWalletAddress,
//   NATIVE,
// } from "../services/Helper.js";
// import TransactionHistory from "../components/TransactionHistory.js";
// import { TokensList } from "../components/TokensList.js";
// import { useSelector } from "react-redux";

// const Home = () => {
//   const selectedNetwork = useSelector((state) => state.network);
//   const livePrice = useSelector((state) => state.price);
//   const [balance, setBalance] = useState(0);
//   const [walletAddress, setWalletAddress] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [importTokenList, setImportTokenList] = useState([]);
//   const [reload, setReload] = useState(false);
//   const navigate = useNavigate();

//   console.log("balance is:", balance);
//   console.log("importTokenList : ", importTokenList);

//   // Get Native Token Balance
//   const getBalance = useCallback(async () => {
//     if (!walletAddress || !selectedNetwork?.chainId) return;
//     setIsLoading(true);
//     try {
//       const nativeBalance = await getNativeTokenBalance(
//         selectedNetwork,
//         walletAddress
//       );
//       setBalance(nativeBalance);
//     } catch (error) {
//       console.error("Error fetching native balance:", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [walletAddress, selectedNetwork]);

//   // Fetch Wallet Address
//   const setUserWalletAddress = useCallback(async () => {
//     try {
//       const address = await getWalletAddress(
//         selectedNetwork?.blockchainType.toUpperCase()
//       );
//       if (address !== walletAddress) setWalletAddress(address); // Update only if changed
//     } catch (error) {
//       console.error("Error fetching wallet address:", error);
//     }
//   }, [selectedNetwork?.blockchainType, walletAddress]);

//   // Fetch Token List
//   const fetchTokenList = useCallback(async () => {
//     if (!walletAddress || !selectedNetwork?.chainId) return;
//     if (selectedNetwork?.blockchainType.toUpperCase() === "BITCOIN") {
//       setImportTokenList([]);
//       return;
//     }

//     setIsLoading(true);
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_API_URL}/tokens/get-user-tokens-list-with-balances`,
//         { chainId: selectedNetwork.chainId },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       setImportTokenList(response?.data?.response || []);
//     } catch (error) {
//       console.error("Error fetching token list: ", error);
//     } finally {
//       setIsLoading(false);
//     }
//   }, [selectedNetwork?.chainId, walletAddress, reload]);

//   // Consolidated Effect for Balance and Tokens
//   useEffect(() => {
//     if (walletAddress && selectedNetwork) {
//       getBalance();
//       fetchTokenList();
//     }
//   }, [walletAddress, selectedNetwork, getBalance, fetchTokenList]);

//   // Initial Fetch Wallet Address
//   useEffect(() => {
//     setUserWalletAddress();
//   }, [setUserWalletAddress]);

//   return (
//     <>
//       {isLoading && <Loader />}
//       <div className="extensions-box">
//         <Head />
//         <div className="home-bg padd20">
//           <WalletBalance
//             network={selectedNetwork}
//             balance={balance}
//             livePrice={livePrice}
//             isNative={true}
//           />
//           <div className="send-receive-btn">
//             <button
//               className="border-btn"
//               onClick={() => {
//                 const data = {
//                   chainId: selectedNetwork?.chainId,
//                   contractAddress: NATIVE,
//                   decimals:
//                     selectedNetwork?.blockchainType.toUpperCase() === "TRON"
//                       ? 6
//                       : 18,
//                   name: selectedNetwork?.name,
//                   symbol: selectedNetwork?.symbol,
//                 };

//                 navigate("/send-crypto", {
//                   state: data,
//                 });
//               }}
//             >
//               <i className="ti ti-arrow-up-right" />
//               <span>Send</span>
//             </button>
//             <Link to="/receive-crypto" className="border-btn">
//               <i className="ti ti-arrow-down-left" />
//               <span>Receive</span>
//             </Link>
//           </div>

//           <div className="tokens-transactions-box">
//             <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
//               <li className="nav-item" role="presentation">
//                 <button
//                   className="nav-link active"
//                   id="pills-home-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#pills-home"
//                   type="button"
//                   role="tab"
//                   aria-controls="pills-home"
//                   aria-selected="true"
//                 >
//                   Tokens
//                 </button>
//               </li>
//               <li className="nav-item" role="presentation">
//                 <button
//                   className="nav-link"
//                   id="pills-profile-tab"
//                   data-bs-toggle="pill"
//                   data-bs-target="#pills-profile"
//                   type="button"
//                   role="tab"
//                   aria-controls="pills-profile"
//                   aria-selected="false"
//                 >
//                   Transactions
//                 </button>
//               </li>
//             </ul>
//             <div className="tab-content" id="pills-tabContent">
//               <div
//                 className="tab-pane fade show active"
//                 id="pills-home"
//                 role="tabpanel"
//                 aria-labelledby="pills-home-tab"
//               >
//                 <TokensList
//                   network={selectedNetwork}
//                   balance={balance}
//                   importTokenList={importTokenList}
//                   isLoading={isLoading}
//                 />

//                 {selectedNetwork?.blockchainType.toUpperCase() !==
//                   "BITCOIN" && (
//                   <div className="import-token-btn">
//                     <a
//                       href="#"
//                       data-bs-toggle="modal"
//                       data-bs-target="#exampleModal"
//                     >
//                       <i className="ti ti-download" />
//                       <span>Import Token</span>
//                     </a>
//                   </div>
//                 )}
//               </div>
//               <div
//                 className="tab-pane fade"
//                 id="pills-profile"
//                 role="tabpanel"
//                 aria-labelledby="pills-profile-tab"
//               >
//                 <TransactionHistory />
//               </div>
//             </div>
//           </div>
//         </div>

//         <ImportToken setReload={setReload} reload={reload} />
//       </div>
//     </>
//   );
// };

// export default Home;

import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Head from "../components/Head";
import ImportToken from "../components/Models/ImportToken.js";
import WalletBalance from "../components/BalanceCard.js";
import { Loader } from "../components/Loader/Loader";
import axios from "axios";
import {
  getNativeTokenBalance,
  getWalletAddress,
  NATIVE,
} from "../services/Helper.js";
import TransactionHistory from "../components/TransactionHistory.js";
import { TokensList } from "../components/TokensList.js";
import { useSelector } from "react-redux";
import AlertModel from "../components/Models/AlertModel.js";

const Home = () => {
  const selectedNetwork = useSelector((state) => state.network);
  const livePrice = useSelector((state) => state.price);
  const [balance, setBalance] = useState(0);
  const [walletAddress, setWalletAddress] = useState(null);
  const [loadingCount, setLoadingCount] = useState(0);
  const [importTokenList, setImportTokenList] = useState([]);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  console.log("balance is:", balance);
  console.log("importTokenList : ", importTokenList);

  // Increment loading count
  const incrementLoading = () => setLoadingCount((prevCount) => prevCount + 1);
  // Decrement loading count
  const decrementLoading = () => setLoadingCount((prevCount) => prevCount - 1);

  // Get Native Token Balance
  const getBalance = useCallback(async () => {
    if (!walletAddress || !selectedNetwork?.chainId) return;
    incrementLoading();
    try {
      const nativeBalance = await getNativeTokenBalance(
        selectedNetwork,
        walletAddress
      );
      setBalance(nativeBalance);
    } catch (error) {
      console.error("Error fetching native balance:", error);
    } finally {
      decrementLoading();
    }
  }, [walletAddress, selectedNetwork]);

  // Fetch Wallet Address
  const setUserWalletAddress = useCallback(async () => {
    try {
      incrementLoading();
      const address = await getWalletAddress(
        selectedNetwork?.blockchainType.toUpperCase()
      );
      if (address !== walletAddress) setWalletAddress(address); // Update only if changed
    } catch (error) {
      console.error("Error fetching wallet address:", error);
    } finally {
      decrementLoading();
    }
  }, [selectedNetwork?.blockchainType, walletAddress]);

  // Fetch Token List
  const fetchTokenList = useCallback(async () => {
    if (!walletAddress || !selectedNetwork?.chainId) return;
    if (selectedNetwork?.blockchainType.toUpperCase() === "BITCOIN") {
      setImportTokenList([]);
      return;
    }

    incrementLoading();
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tokens/get-user-tokens-list-with-balances`,
        { chainId: selectedNetwork.chainId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setImportTokenList(response?.data?.response || []);
    } catch (error) {
      console.error("Error fetching token list: ", error);
    } finally {
      decrementLoading();
    }
  }, [selectedNetwork?.chainId, walletAddress, reload]);

  // Consolidated Effect for Balance and Tokens
  useEffect(() => {
    if (walletAddress && selectedNetwork) {
      getBalance();
      fetchTokenList();
    }
  }, [walletAddress, selectedNetwork, getBalance, fetchTokenList]);

  // Initial Fetch Wallet Address
  useEffect(() => {
    setUserWalletAddress();
  }, [setUserWalletAddress]);

  return (
    <>
      {loadingCount > 0 && <Loader />}{" "}
      {/* Show loader only if loadingCount > 0 */}
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <WalletBalance
            network={selectedNetwork}
            balance={balance}
            livePrice={livePrice}
            isNative={true}
          />
          <div className="send-receive-btn">
            <button
              className="border-btn"
              onClick={() => {
                const data = {
                  chainId: selectedNetwork?.chainId,
                  contractAddress: NATIVE,
                  decimals:
                    selectedNetwork?.blockchainType.toUpperCase() === "TRON"
                      ? 6
                      : 18,
                  name: selectedNetwork?.name,
                  symbol: selectedNetwork?.symbol,
                };

                navigate("/send-crypto", {
                  state: data,
                });
              }}
            >
              <i className="ti ti-arrow-up-right" />
              <span>Send</span>
            </button>
            <Link to="/receive-crypto" className="border-btn">
              <i className="ti ti-arrow-down-left" />
              <span>Receive</span>
            </Link>
          </div>

          <div className="tokens-transactions-box">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Tokens
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Transactions
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <TokensList
                  network={selectedNetwork}
                  balance={balance}
                  importTokenList={importTokenList}
                  isLoading={loadingCount > 0} // Pass loading state based on loadingCount
                />

                {selectedNetwork?.blockchainType.toUpperCase() !==
                  "BITCOIN" && (
                  <div className="import-token-btn">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ti ti-download" />
                      <span>Import Token</span>
                    </a>
                  </div>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <TransactionHistory />
              </div>
            </div>
          </div>
        </div>

        <ImportToken setReload={setReload} reload={reload} />
        {/* <AlertModel status={false} title={"Transaction successful"} /> */}
      </div>
    </>
  );
};

export default Home;
