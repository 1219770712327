import axios from "axios";
import { TokenABI } from "./TokenABI";
import {
  BNB_ADDRESS_SCAN_URL,
  BNB_CHAIN_ID,
  BTC_ADDRESS_SCAN_URL,
  BTC_CHAIN_ID,
  ETH_ADDRESS_SCAN_URL,
  ETH_CHAIN_ID,
  POLYGON_ADDRESS_SCAN_URL,
  POLYGON_CHAIN_ID,
  TRON_ADDRESS_SCAN_URL,
  TRON_CHAIN_ID,
} from "./constaint";
const Web3 = require("web3");
const { TronWeb } = require("tronweb");
const { validate } = require("bitcoin-address-validation");
// import { validate, getAddressInfo } from "bitcoin-address-validation";

const { Buffer } = require("buffer");

// Make Buffer globally available
window.Buffer = Buffer;

export const getTronTRC20Balance = async (contractAddress) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/tokens/get-trc20-token-balance`;
    const response = await axios.post(
      url,
      { tokenAddresses: contractAddress },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );

    if (response.status === 201) {
      const data = response.data?.response[0];
      const balance = data?.balance / 10 ** data?.decimal;
      console.log("---- data : ", data, balance);

      return balance;
    }

    // return userBalance.toString();
    return 0;
  } catch (error) {
    console.log("Error fetching TRC20 token balance:", error);
    return 0;
  }
};

export const validateTronAddress = (address) => {
  return TronWeb.isAddress(address);
};

export const validateBitcoinAddress = (address) => {
  return validate(address);
};

export const getTronNativeBalance = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/users/tron-balance`;
    const balance = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log("balance : ", balance);

    return balance;
  } catch (error) {
    console.error("Error getting Tron native balance:", error);
    return 0;
  }
};

export const validateEvmAddress = async (address) => {
  const web3 = await getWeb3(BNB_CHAIN_ID);
  const isValidAddress = web3.utils.isAddress(address);
  console.log("isValidAddress : ", isValidAddress);
  return isValidAddress;
};

export const NATIVE = "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE";

export const getCoinPrice = async () => {
  try {
    const response = await fetch(
      "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Ctether%2Cbinancecoin%2Ctron%2Cavalanche-2%2Cmatic-network%2Cmatic-network%2Csolana&vs_currencies=USD&precision=2",
      {
        method: "GET",
        headers: {
          accept: "application/json",
          "x-cg-api-key": "CG-tSwDtVsi4HssjvJboRfkSZvH",
        },
      }
    );
    const data = await response.json();
    const livePrice = {
      ETH: data?.ethereum?.usd ?? 0,
      BNB: data?.binancecoin?.usd ?? 0,
      AVAX: data?.["avalanche-2"]?.usd ?? 0, // Fixed the access to Avalanche price
      BTC: data?.bitcoin?.usd ?? 0,
      MATIC: data?.["matic-network"]?.usd ?? 0,
      SOL: data?.solana?.usd ?? 0,
      USDT: data?.tether?.usd ?? 0,
      TRX: data?.tron?.usd ?? 0,
    };

    return livePrice;
  } catch (error) {
    console.log("Error in getCoinPrice : ", error);
  }
};

export const getWalletAddress = async (blockchainType) => {
  console.log("------------------- getWalletAddress called");

  const address = JSON.parse(localStorage.getItem("publicKey"));

  if (blockchainType === "EVM") {
    return address?.evmAddress;
  }
  if (blockchainType === "TRON") {
    return address?.tronAddress;
  }
  if (blockchainType === "BITCOIN") {
    return address?.bitcoinAddress;
  }
};

// all chainId and RPC list
export const networkList = [
  {
    name: "Sepolia",
    icon: "images/crypto-icon2.png",
    networkName: "Sepolia Testnet",
    blockchainType: "EVM",
    rpc: getNetwork(ETH_CHAIN_ID).rpc,
    chainId: ETH_CHAIN_ID,
    symbol: "ETH",
    explorerURL: "https://sepolia.etherscan.io/tx/",
  },
  {
    name: "Binance Testnet",
    icon: "images/crypto-icon3.png",
    networkName: "Binance Smart Chain Testnet",
    blockchainType: "EVM",
    rpc: getNetwork(BNB_CHAIN_ID).rpc,
    chainId: BNB_CHAIN_ID,
    symbol: "BNB",
    explorerURL: "https://testnet.bscscan.com/tx/",
  },
  {
    name: "Polygon Testnet",
    icon: "images/crypto-icon4.png",
    networkName: "Polygon Matic",
    blockchainType: "EVM",
    rpc: getNetwork(POLYGON_CHAIN_ID).rpc,
    chainId: POLYGON_CHAIN_ID,
    symbol: "MATIC",
    explorerURL: "https://amoy.polygonscan.com/tx/",
  },
  // {
  //   name: "Avlanch Mainnet",
  //   icon: "images/crypto-icon8.png",
  //   networkName: "Avlanch",
  //   blockchainType: "EVM",
  //   rpc: getNetwork(43114).rpc,
  //   chainId: 43114,
  //   symbol: "AVAX",
  //   explorerURL: "https://testnet.avascan.info/",
  // },
  {
    name: "Tron Testnet",
    icon: "images/crypto-icon7.png",
    networkName: "TRON",
    blockchainType: "TRON",
    rpc: getNetwork(TRON_CHAIN_ID).rpc,
    chainId: TRON_CHAIN_ID,
    symbol: "TRX",
    explorerURL: "https://shasta.tronscan.org/#/transaction/",
  },
  {
    name: "Bitcoin Testnet",
    icon: "images/bitcoin1.png",
    networkName: "Bitcoin",
    blockchainType: "BITCOIN",
    rpc: getNetwork(BTC_CHAIN_ID).rpc,
    chainId: BTC_CHAIN_ID,
    symbol: "BTC",
    explorerURL: "https://mempool.space/tx/",
    // explorerURL: "https://mempool.space/testnet/tx/",
  },
];

const getWeb3 = async (chainId) => {
  const rpc = getNetwork(chainId).rpc;
  const web3 = new Web3(rpc);
  return web3;
};

export const getNativeTokenBalance = async (selectedNetwork, address) => {
  console.log(
    "--------------------------------- getNativeTokenBalance called :  ",
    selectedNetwork
  );

  try {
    if (selectedNetwork?.blockchainType.toUpperCase() === "EVM") {
      const web3 = await getWeb3(selectedNetwork.chainId);
      let balance = await web3.eth.getBalance(address);

      balance = web3.utils.fromWei(balance, "ether");
      console.log("Native balance is : ", balance);
      return balance;
    } else if (selectedNetwork?.blockchainType?.toUpperCase() === "TRON") {
      const url = `${process.env.REACT_APP_API_URL}/users/tron-balance`;
      // Use await to return the balance directly
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      console.log("Native balance response is : ", response?.data?.balance);

      // Ensure balance is returned
      return response?.data?.balance;
    } else {
      const url = `${process.env.REACT_APP_API_URL}/users/bitcoin-balance`;
      // Use await to return the balance directly
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const balance = response?.data?.balance?.data;

      console.log("Native balance response is : ", balance);

      // Ensure balance is returned
      return parseFloat(balance?.incoming) - parseFloat(balance?.outgoing) ?? 0;
    }
  } catch (error) {
    console.log("Error in getNativeTokenBalance : ", error);
    return 0;
  }
};

export const formatNumber = (input, decimalPlace) => {
  try {
    let str = input.toString();
    let dotIndex = str.indexOf(".");
    if (dotIndex === -1) {
      return str;
    }
    let preDot = str.substring(0, dotIndex + 1);
    let postDot = str.substring(dotIndex + 1, dotIndex + decimalPlace + 1);

    return preDot + postDot;
  } catch (error) {
    console.log("error in format Number : ", error);
    return 0;
  }
};

export async function getContractData(tokenContractAddress) {
  const data = {
    error: null,
    status: "fail",
    info: {
      name: null,
      decimal: null,
      symbol: null,
    },
  };

  try {
    const network = JSON.parse(localStorage.getItem("selectedCrypto"));
    const web3 = new Web3(network?.rpc); // Initialize Web3 with the network's RPC URL

    const copperContract = new web3.eth.Contract(
      TokenABI,
      tokenContractAddress
    );

    data.info.name = await copperContract.methods.name().call(); // Get the token name
    data.info.decimal = await copperContract.methods.decimals().call(); // Get the token decimals
    data.info.symbol = await copperContract.methods.symbol().call(); // Get the token symbol

    data.status = "success";

    return data;
  } catch (e) {
    console.log(e);
    data.error = "Invalid input data";
    return data;
  }
}

export async function estimateGasFee(receiverAddress, amount) {
  try {
    const network = JSON.parse(localStorage.getItem("selectedCrypto"));
    const web3 = new Web3(network?.rpc); // Initialize Web3 with the network's RPC URL

    // Convert amount to Wei (Web3.js uses Wei for Ether values)
    const amountInWei = web3.utils.toWei(amount.toString(), "ether");

    const tx = {
      to: receiverAddress,
      value: amountInWei,
    };

    // Estimate gas for the transaction
    const gasEstimate = await web3.eth.estimateGas(tx);

    // Get current gas price
    const gasPrice = await web3.eth.getGasPrice();

    // Calculate the total gas fee (gasLimit * gasPrice)
    const totalGasFee = web3.utils
      .toBN(gasEstimate)
      .mul(web3.utils.toBN(gasPrice));

    return totalGasFee;
  } catch (error) {
    console.error("Error estimating gas fee: ", error);
    return 0;
  }
}

export const addressFormat = (address, charlength) => {
  if (!address || typeof address !== "string") {
    return ""; // Return an empty string if address is null, undefined, or not a string
  }

  if (address.length <= charlength * 2) {
    return address;
  }
  return `${address.substring(0, charlength)}...${address.substring(
    address.length - charlength
  )}`;
};

export async function getERC20Balance(tokenContractAddress, walletAddress) {
  try {
    const network = JSON.parse(localStorage.getItem("selectedCrypto"));
    const web3 = new Web3(network?.rpc); // Initialize Web3 with the network's RPC URL

    // Create the contract instance using Web3.js
    const contract = new web3.eth.Contract(TokenABI, tokenContractAddress);

    // Get the token's decimal places
    const decimals = await contract.methods.decimals().call();

    // Get the token balance of the wallet
    const balance = await contract.methods.balanceOf(walletAddress).call();

    // Convert the balance from its smallest unit (e.g., Wei for ERC20) to a readable number
    const parsedBalance =
      web3.utils.fromWei(balance, "ether") / 10 ** (decimals - 18);

    return parsedBalance;
  } catch (e) {
    console.error("Error fetching ERC20 balance: ", e);
    return 0;
  }
}

export function getNetwork(chainId) {
  switch (chainId.toString()) {
    case ETH_CHAIN_ID:
      return {
        network: "Ethereum",
        symbol: "ETH",
        rpc: "https://site1.moralis-nodes.com/sepolia/b9f317ba31f94131be7850d821725543",
      };
    case BNB_CHAIN_ID:
      return {
        network: "Binance Smart Chain",
        symbol: "BNB",
        rpc: "https://site1.moralis-nodes.com/bsc-testnet/955586d16b7d410f895f51b75e47caf9",
      };

    case POLYGON_CHAIN_ID:
      return {
        network: "Polygon Testnet",
        symbol: "MATIC",
        rpc: "https://site1.moralis-nodes.com/amoy/9cd0ff9e4ca5423cbb649ca7ad843def",
      };
    // case 43114:
    //   return {
    //     network: "Avalanche",
    //     symbol: "AVAX",
    //     rpc: "https://site1.moralis-nodes.com/avalanche/cf7ab46a7e2d41fdba7f9c38a8d07e0b",
    //     // rpc: "https://api.avax-test.network/ext/bc/C/rpc",
    //   };

    case TRON_CHAIN_ID:
      return {
        network: "TRON",
        symbol: "TRX",
      };

    case BTC_CHAIN_ID:
      return {
        network: "Bitcoin",
        symbol: "BTC",
      };

    default:
      throw new Error("Unsupported chainId");
  }
}

export const trimContent = (text, length) => {
  if (!text || typeof text !== "string") {
    return "";
  }

  if (text.length <= length) {
    return text;
  } else {
    return text.slice(0, length) + "...";
  }
};

// New Functions ----------------------------------------------------------------

function getDecimalUnit(decimal) {
  const units = {
    1: "wei",
    3: "kwei",
    6: "mwei",
    9: "gwei",
    12: "szabo",
    15: "finney",
    18: "ether",
  };

  return units[decimal.toString()] || "Unit not found";
}

export async function checkWalletAllTransactionsForAddress() {
  const selectedCrypto = await JSON.parse(
    localStorage.getItem("selectedCrypto")
  );

  const address = await getWalletAddress(selectedCrypto.blockchainType);

  if (selectedCrypto.chainId === BNB_CHAIN_ID) {
    return BNB_ADDRESS_SCAN_URL + address;
  } else if (selectedCrypto.chainId === ETH_CHAIN_ID) {
    return ETH_ADDRESS_SCAN_URL + address;
  } else if (selectedCrypto.chainId === POLYGON_CHAIN_ID) {
    return POLYGON_ADDRESS_SCAN_URL + address;
  } else if (selectedCrypto.chainId === TRON_CHAIN_ID) {
    return TRON_ADDRESS_SCAN_URL + address;
  } else if (selectedCrypto.chainId === BTC_CHAIN_ID) {
    return BTC_ADDRESS_SCAN_URL + address;
  } else {
    throw new Error("Unsupported chainId");
  }
}
