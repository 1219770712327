import { io } from "socket.io-client";

const URL = process.env.REACT_APP_API_URL;
const WEB_SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL;

console.log("URL for socket connection is : ", URL, WEB_SOCKET_URL);

// Establish the Socket.io connection to the server
// export const socket = io("wss://crypto-extentionapi.devtechnosys.tech", {
export const socket = io(WEB_SOCKET_URL, {
  autoConnect: true,
  transports: ["websocket", "polling"], // Define transport types for fallback
  withCredentials: false, // Set this if using authentication tokens across origins
  reconnectionAttempts: 5, // Retry connection if it fails (optional)
  secure: true,
});
