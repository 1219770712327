import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Head from "../../components/Head";
import AlertModel from "../../components/Models/AlertModel";

const MyProfile = () => {
  const [userId, setUserId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [alertData, setAlertData] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/user-profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        setUserId(response?.data?.data?._id);
        setFormData({
          name: response?.data?.data?.name,
          email: response?.data?.data?.email,
        });
      } catch (error) {
        console.error("Error fetching adminn details:", error);
      }
    };
    fetchUserDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.name) {
      newErrors.name = "Name is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    try {
      await axios
        .put(`${process.env.REACT_APP_API_URL}/users/update-user-profile`, {
          id: userId,
          ...formData,
        })
        .then((resp) => {
          if (resp?.status == 200) {
            setAlertData({
              status: true,
              title: "Profile Updated",
              subtitle: "Your profile has been updated successfully.",
              navigation: "/my-profile",
            });
          } else {
            setAlertData({
              status: false,
              title: "Update Profile",
              subtitle: "Failed to update your profile. Please try again.",
            });
          }
        });
    } catch (error) {
      console.error("Error updating admin details:", error);
      setAlertData({
        status: false,
        title: "Update Profile",
        subtitle: "Failed to update your profile. Please try again.",
      });
    }
  };

  return (
    <>
      {alertData && (
        <AlertModel
          status={alertData.status}
          title={alertData.title}
          subtitle={alertData.subtitle}
          navigation={alertData.navigation}
        />
      )}
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/account">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <form onSubmit={handleFormSubmit}>
            <div className="send-crypto-des">
              <h2>My Profile</h2>
              <div className="form-group">
                <label>Name</label>
                <div className="form-group-inner">
                  <div className="fild-icon">
                    <i className="ti ti-user" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    name="name"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <div className="form-group-inner">
                  <div className="fild-icon">
                    <i className="ti ti-mail" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter email"
                    name="email"
                    className={`form-control ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled
                  />
                  {/* {errors.email && (
                    <div className="invalid-feedback">{errors.email}</div>
                  )} */}
                </div>
              </div>
              <div className="form-submit-btn">
                <button className="btn">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
