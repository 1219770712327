import { createSlice } from "@reduxjs/toolkit";

const initialState =
  typeof window !== "undefined" && localStorage.getItem("selectedCrypto")
    ? JSON.parse(localStorage.getItem("selectedCrypto"))
    : null;

export const networkSlice = createSlice({
  name: "network",
  initialState,
  reducers: {
    updateNetwork: (state, action) => {
      console.log("state and action", state, action);

      const updatedState = { ...state, ...action.payload };
      if (typeof window !== "undefined") {
        localStorage.setItem("selectedCrypto", JSON.stringify(updatedState));
      }
      return updatedState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateNetwork } = networkSlice.actions;

export default networkSlice.reducer;
