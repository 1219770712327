import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Head from "../components/Head";
import { QRCodeCanvas } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";

const ReceiveCrypto = () => {
  const [walletAddress, setWalletAddress] = useState("");
  const [copied, setCopied] = useState(false);

  const network = useSelector((state) => state.network);

  useEffect(() => {
    const address = JSON.parse(localStorage.getItem("publicKey"));
    console.log("EVM is here 899999: ", network.blockchainType);
    if (network?.blockchainType.toUpperCase() === "EVM") {
      setWalletAddress(address?.evmAddress);
    }
    if (network?.blockchainType.toUpperCase() === "TRON") {
      setWalletAddress(address?.tronAddress);
    }

    if (network?.blockchainType.toUpperCase() === "BITCOIN") {
      setWalletAddress(address?.bitcoinAddress);
    }
  }, [network]);

  useEffect(() => {
    let timer;
    if (copied) {
      timer = setTimeout(() => setCopied(false), 2000);
    }
    return () => clearTimeout(timer);
  }, [copied]);

  console.log("walletAddress : ", walletAddress);

  return (
    <>
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <div className="send-crypto-des">
            <div className="receive-crypto-box">
              <h2>Receive Crypto</h2>
              <p>Use this QR Code to receive payments</p>
            </div>
            <div className="qr-box">
              {walletAddress ? (
                <QRCodeCanvas value={walletAddress} />
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="wallet-address-box">
              <h5>Your Wallet Address</h5>
              <div className="wallet-code-box">
                <p>{walletAddress}</p>
                <CopyToClipboard
                  text={walletAddress}
                  onCopy={() => setCopied(true)}
                >
                  <button>
                    {copied ? (
                      <i className="ti ti-checkbox" />
                    ) : (
                      <i className="ti ti-copy" />
                    )}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiveCrypto;
