import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AlertModel = ({ status, title, subtitle, navigation }) => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpen(false);
    if (navigation) {
      navigate(navigation);
    }
  };

  return (
    <>
      {/* Modal */}
      {isOpen && (
        <div
          className="modal fade import-token-bg show"
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden={!isOpen}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div
                style={{
                  backgroundColor: "rgba(235, 200, 60, 0.28)", // Semi-transparent yellow
                  borderRadius: "50%", // Makes it a circle
                  padding: "40px",
                  width: "56px",
                  height: "56px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: "80%",
                  margin: "0 auto",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <i
                  className={`ti ti-${status === true ? "check" : "x"}`}
                  style={{
                    color: "#D8A80A", // Solid yellow
                    fontSize: "36px",
                  }}
                />
              </div>

              <div className="modal-body">
                <div className="import-token-des">
                  <h2>{title}</h2>
                  <div className="tokens-transactions-box">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="pills-profile2"
                        role="tabpanel"
                        aria-labelledby="pills-profile-tab2"
                      >
                        <p
                          style={{
                            textAlign: "center",
                            marginTop: "20px",
                            marginBottom: "20px",
                            fontSize: "16px",
                          }}
                        >
                          {subtitle}
                        </p>
                        <button onClick={closeModal} className="btn">
                          Ok
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AlertModel;
