import { io } from "socket.io-client";

const URL = process.env.REACT_APP_API_URL;

console.log("URL for socket connection is : ", URL);

// Establish the Socket.io connection to the server
export const socket = io("wss://crypto-extentionapi.devtechnosys.tech", {
  autoConnect: true,
  transports: ["websocket", "polling"], // Define transport types for fallback
  withCredentials: false, // Set this if using authentication tokens across origins
  reconnectionAttempts: 5, // Retry connection if it fails (optional)
  secure: true,
});
