import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import Head from "../components/Head";
import ImportToken from "../components/Models/ImportToken.js";
import WalletBalance from "../components/BalanceCard.js";
import { Loader } from "../components/Loader/Loader"; // Import your Loader component
import axios from "axios";
import {
  getNativeTokenBalance,
  getWalletAddress,
  NATIVE,
} from "../services/Helper.js";
import TransactionHistory from "../components/TransactionHistory.js";
import { TokensList } from "../components/TokensList.js";
import { useSelector } from "react-redux";

const Home = () => {
  const selectedNetwork = useSelector((state) => state.network);
  const livePrice = useSelector((state) => state.price);
  const [balance, setBalance] = useState(0);
  const [walletAddress, setWalletAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [importTokenList, setImportTokenList] = useState([]);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();

  console.log("importTokenList : ", importTokenList);

  console.log("balance is : ", balance);

  // Get Native Token Balance
  const getBalance = useCallback(async () => {
    if (!walletAddress || !selectedNetwork?.chainId) return;
    setIsLoading(true);
    try {
      const nativeBalance = await getNativeTokenBalance(
        selectedNetwork,
        walletAddress
      );
      console.log("nativeBalance ------+-+----: ", nativeBalance);

      setBalance(nativeBalance);
    } catch (error) {
      console.error("Error fetching native balance:", error);
    } finally {
      setIsLoading(false);
    }
  }, [walletAddress, selectedNetwork]);

  // Fetch Wallet Address
  const setUserWalletAddress = useCallback(async () => {
    try {
      const walletAddress = await getWalletAddress(
        selectedNetwork?.blockchainType
      );
      setWalletAddress(walletAddress);
    } catch (error) {
      console.error("Error fetching wallet address:", error);
    }
  }, [selectedNetwork?.blockchainType]);

  // Fetch Token List
  const fetchTokenList = useCallback(async () => {
    if (!walletAddress || !selectedNetwork?.chainId) return;
    if (selectedNetwork?.blockchainType === "bitcoin") {
      setImportTokenList([]);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tokens/get-user-tokens-list-with-balances`,
        { chainId: selectedNetwork.chainId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setReload(!reload);
      setImportTokenList(response?.data?.response || []);
    } catch (error) {
      console.error("Error fetching token list: ", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedNetwork?.chainId, walletAddress]);

  // Load balance and tokens when walletAddress or network changes
  useEffect(() => {
    if (walletAddress && selectedNetwork) {
      getBalance();
      fetchTokenList();
    }
  }, [walletAddress, selectedNetwork, getBalance, livePrice, reload]);

  // Fetch wallet address on initial load
  useEffect(() => {
    setUserWalletAddress();
  }, [setUserWalletAddress]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <WalletBalance
            network={selectedNetwork}
            balance={balance}
            livePrice={livePrice}
            isNative={true}
          />
          <div className="send-receive-btn">
            <button
              className="border-btn"
              onClick={() => {
                let data = {
                  chainId: selectedNetwork?.chainId,
                  contractAddress: NATIVE,
                  decimals: selectedNetwork?.blockchainType === "tron" ? 6 : 18,
                  name: selectedNetwork?.name,
                  symbol: selectedNetwork?.symbol,
                };

                navigate("/send-crypto", {
                  state: data,
                });
              }}
            >
              <i className="ti ti-arrow-up-right" />
              <span>Send</span>
            </button>
            <Link to="/receive-crypto" className="border-btn">
              <i className="ti ti-arrow-down-left" />
              <span>Receive</span>
            </Link>
          </div>

          <div className="tokens-transactions-box">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Tokens
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  Transactions
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <TokensList
                  network={selectedNetwork}
                  balance={balance}
                  importTokenList={importTokenList}
                />

                {selectedNetwork?.blockchainType !== "bitcoin" && (
                  <div className="import-token-btn">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="ti ti-download" />
                      <span>Import Token</span>
                    </a>
                  </div>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
              >
                <TransactionHistory />
              </div>
            </div>
          </div>
        </div>

        <ImportToken setReload={setReload} reload={reload} />
      </div>
    </>
  );
};

export default Home;
