import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { defaultPrice, getCoinPrice, networkList } from "../services/Helper";
import { useDispatch } from "react-redux";
import { updateNetwork } from "../services/redux/networkSlice";
import { setPrice } from "../services/redux/cryptoPriceSlice";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [wrongCrediantals, setWrongCrediantals] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validate = () => {
    const newErrors = {};

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    // password validation
    if (!formData.password) {
      newErrors.password = "Password is required";
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await axios
          .post(`${process.env.REACT_APP_API_URL}/auth/user-login`, formData)
          .then(async (response) => {
            if (response.data.access_token) {
              localStorage.setItem("token", response?.data?.access_token);
              console.log("response?.data : ", response?.data);

              const publicKey = JSON.stringify({
                evmAddress: response?.data?.publicKey,
                tronAddress: response?.data?.tronPublicKey,
                bitcoinAddress: response?.data?.bitcoinPublicKey,
              });
              console.log("publicKey is : ", publicKey);

              localStorage.setItem("publicKey", publicKey);

              // if there is nothing in selectedCrypto then put the default data into localStorage
              if (!localStorage.getItem("selectedCrypto")) {
                const defaultCrypto = networkList[0];
                localStorage.setItem(
                  "selectedCrypto",
                  JSON.stringify(defaultCrypto)
                );
                dispatch(updateNetwork(defaultCrypto));
              }

              const livePrice = await getCoinPrice();
              dispatch(setPrice(livePrice));

              setErrors("");
              navigate("/");
            } else {
              setWrongCrediantals("Invalid email or password");
            }
          })
          .catch((error) => {
            console.error("errorr", error);
            setErrors("");
            setWrongCrediantals("Invalid email or password");
          });
      } catch (error) {
        console.log("catch error", error);
        setWrongCrediantals("Invalid email or password");
      }
    } else {
      setErrors(formErrors);
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <div className="extensions-box">
        <div className="form-content-box">
          <form onSubmit={handleSubmit}>
            <div className="form-logo">
              <a href="#">
                <img src="images/logo.png" alt="logo" />
              </a>
            </div>
            <h2>Welcome Back!</h2>
            <div className="form-group">
              <label>Email</label>
              <div className="form-group-inner">
                <div className="fild-icon">
                  <i className="ti ti-mail" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={handleChange}
                  name="email"
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
            </div>
            <div className="form-group">
              <label>Password</label>
              <div className="form-group-inner password-fild-box">
                <div className="fild-icon">
                  <i className="ti ti-lock" />
                </div>
                <input
                  type={passwordVisible ? "text" : "password"}
                  className="form-control"
                  placeholder="Enter Password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {errors.password && (
                  <span className="error">{errors.password}</span>
                )}
                <div
                  className="eye-view-btn"
                  onClick={togglePasswordVisibility}
                >
                  <i
                    className={`ti ${
                      !passwordVisible ? "ti-eye-off" : "ti-eye"
                    }`}
                  />
                </div>
              </div>
            </div>
            <span className="error">{wrongCrediantals}</span>
            <div className="form-submit-btn">
              <button className="btn">Log In</button>
            </div>
            <div className="forgot-btn">
              <Link to="/signup" href="#">
                SignUp
              </Link>
            </div>
            <div className="forgot-btn">
              <Link to="/forget-password" href="#">
                Forgot Password
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
