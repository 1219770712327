import React, { useState, useEffect } from "react";
import { formatNumber } from "../services/Helper.js";

const BalanceCard = ({ network, balance, symbol, livePrice, isNative }) => {
  const [convertedAmount, setConvertedAmount] = useState(0);

  useEffect(() => {
    const convertCurrency = () => {
      if (network && network?.symbol) {
        const rateValue = livePrice[network?.symbol];

        if (rateValue) {
          const result = balance * rateValue;
          setConvertedAmount(result);
        } else {
          console.warn(`Rate not found for symbol: ${network?.symbol}`);
        }
      }
    };

    convertCurrency();
  }, [balance, network, livePrice]);

  return (
    <>
      <div className="usd-box">
        <h3
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {formatNumber(balance, 7)} {symbol}
          <img
            src={network?.icon}
            alt="Bitcoin Icon"
            style={{ width: "24px", height: "24px", marginLeft: "10px" }}
          />
        </h3>
        {isNative && (
          <p>
            <strong>{formatNumber(convertedAmount, 3)} USD</strong>
          </p>
        )}
        {/* <p>${formatNumber(convertedAmount, 3)} USD</p>{" "} */}
      </div>
    </>
  );
};

export default BalanceCard;
