import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Head from "../../components/Head";
import { socket } from "../../services/socket"; // Ensure you have this service properly set up

const CustomerSupport = () => {
  const [userId, setUserId] = useState(""); // Set dummy userId
  const [adminId] = useState("admin"); // Set dummy adminId
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);

  // Fetch user details and join room (only once when the component mounts)
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        let _userId = null;
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/user-profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        _userId = response?.data?.data?._id;
        setUserId(_userId);

        console.log("response is user : ", _userId);

        // Join room after fetching user details
        socket.emit("handleJoinRoom", {
          userId: _userId,
          adminId,
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
    if (userId) readOldChat();
  }, [userId]); // No dependencies, so this runs only once

  // Listen for incoming messages from admin
  useEffect(() => {
    console.log("This is called");

    try {
      const chatToUser = (chat) => {
        console.log("Chat from admin: ", chat);
        displayMessage("admin", chat.message);
      };

      // Register socket event listeners for receiving messages
      socket.on("chat_to_user", chatToUser);

      // Cleanup socket event listeners when the component unmounts
      return () => {
        socket.off("chat_to_user", chatToUser);
      };
    } catch (e) {
      console.log("Error is : ", e);
    }
  }, []); // No dependencies needed here

  // Function to display messages on the user's chat screen
  const displayMessage = (sender, message) => {
    setChatMessages((prevMessages) => [...prevMessages, { sender, message }]);
  };

  const readOldChat = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/chat/history/${userId}/${adminId}`;
      console.log("url : ", url);

      const response = await axios.get(url);

      const oldChatMessages = response?.data || []; // Assuming the chat data is in response.data.chats
      console.log("oldChatMessages : ", oldChatMessages);

      // Map the old messages to the desired format for display (e.g., sender, message)
      oldChatMessages.map((chat) =>
        displayMessage(chat?.isAdminMessage ? "admin" : "user", chat.message)
      );

      console.log("Response of old chat read: ", oldChatMessages);
    } catch (error) {
      console.error("Error fetching old chat:", error);
    }
  };

  // Function to handle sending messages
  const handleMessageSend = () => {
    if (message.trim()) {
      const payload = {
        userId,
        adminId,
        message,
      };

      // Emit message to server
      socket.emit("message_from_user", payload);

      // Display the message on user's screen
      displayMessage("user", message);
      setMessage(""); // Clear input field
    }
  };

  return (
    <>
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/account">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <div className="send-crypto-des">
            {/* <h2>Customer Support</h2> */}

            <div className="chat-body" id="chat-body">
              {chatMessages.map((chat, index) => (
                <div key={index} className={`message ${chat.sender}`}>
                  <p
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {chat.message}
                  </p>
                </div>
              ))}
            </div>

            <div className="chat-footer">
              <input
                style={{
                  fontSize: "14px",
                }}
                type="text"
                id="message-input"
                placeholder="Type a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <button id="send-button" onClick={handleMessageSend}>
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerSupport;
