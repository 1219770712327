import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Head from "../../components/Head";

const Account = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("selectedCrypto");
    localStorage.removeItem("publicKey");
    localStorage.removeItem("livePrice");

    navigate("/login");
  };
  return (
    <>
      <div className="extensions-box">
        <Head />
        <div className="home-bg padd20">
          <div className="back-box">
            <Link to="/">
              <i className="ti ti-arrow-narrow-left" />
              <span>Back</span>
            </Link>
          </div>
          <div className="send-crypto-des">
            <h2>Account</h2>
            <div className="account-list">
              <ul>
                <li>
                  <a href="#" onClick={() => handleNavigate("/my-profile")}>
                    <i>
                      <img src="images/a-icon1.png" alt="icon" />
                    </i>
                    <span>My Profile</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => handleNavigate("/change-password")}
                  >
                    <i>
                      <img src="images/a-icon2.png" alt="icon" />
                    </i>
                    <span>Change Password</span>
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleNavigate("/secure-storage")}>
                    <i>
                      <img src="images/a-icon3.png" alt="icon" />
                    </i>
                    {/* <span>Secure Storage</span> */}
                    <span>Mnemonic</span>
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_URL}/about-us`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>
                      <img src="images/a-icon4.png" alt="icon" />
                    </i>
                    <span>About Us</span>
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_URL}/terms-conditions`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>
                      <img src="images/a-icon5.png" alt="icon" />
                    </i>
                    <span>Terms &amp; Conditions</span>
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.REACT_APP_URL}/privacy-policy`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i>
                      <img src="images/a-icon6.png" alt="icon" />
                    </i>
                    <span>Privacy Policy</span>
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    onClick={() => handleNavigate("/customer-support")}
                  >
                    <i>
                      <img src="images/a-icon1.png" alt="icon" />
                    </i>
                    <span>Customer Support</span>
                  </a>
                </li>

                <li onClick={handleLogout}>
                  <a href="#">
                    <i>
                      <img src="images/a-icon7.png" alt="icon" />
                    </i>
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
